// https://stackoverflow.com/a/41462752/3689060
const checkIfStorageAvailable = () => {
  try {
    let storage = window.localStorage;
    let x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch(e) {
    return false;
  }
};

const setItem = (key, value) => {
  if (checkIfStorageAvailable()) {
    window.localStorage.setItem(key, value);
  } else {
    return null;
  }
};

const getItem = (key) => {
  if (checkIfStorageAvailable()) {
    return window.localStorage.getItem(key);
  } else {
    return null;
  }
};

const removeItem = (key) => {
  if (checkIfStorageAvailable()) {
    window.localStorage.removeItem(key);
  } else {
    return null;
  }
};

export default {
  setItem,
  getItem,
  removeItem,
};
