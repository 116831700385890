import React from 'react';
import * as Sentry from '@sentry/node';
import App from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { ToastContainer, cssTransition } from 'react-toastify';
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import AnalyticsService from '@services/AnalyticsService';
import '../styles/main.scss';
import 'rc-tooltip/assets/bootstrap.css';
import 'react-virtualized/styles.css';
import 'swiper/swiper.scss';
import 'swiper/components/a11y/a11y.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/lazy/lazy.scss';

if (process.env.SENTRY_PUBLIC_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_PUBLIC_DSN,
    ignoreErrors: [
      'Error: ResizeObserver loop limit exceeded',
    ],
    blacklistUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
  });
}

const Slide = cssTransition({
  enter: 'Toastify__slideInUp',
  exit: 'Toastify__slideOutDown',
});

const analyticsService = new AnalyticsService();

// Router events and NProgress isLoading
NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', (url) => {
  analyticsService.handleRouteChange(url);
  NProgress.done();
});
Router.events.on('routeChangeError', () => NProgress.done());

class MyApp extends App {
  componentDidMount() {
    Router.beforePopState(({ as }) => {
      location.href = as;
    });
  }

  render() {
    const { Component, pageProps } = this.props;

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props;
    const modifiedPageProps = { ...pageProps, err };

    return (
      <>
        <Component {...modifiedPageProps} />
        <ToastContainer
          autoClose={3000}
          closeButton={false}
          hideProgressBar
          position="bottom-center"
          transition={Slide}
        />
      </>
    );
  }
}

export default MyApp;
