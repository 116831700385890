import * as Sentry from '@sentry/node';
import { v1 as uuidv1 } from 'uuid';
import LocalStorageService from '@services/LocalStorageService';

class AnalyticsService {
  /**
   * Manually track the page change
   */
  handleRouteChange (path) {
    if (typeof analytics === 'undefined' || analytics === null) return;
    analytics.page('Page Track', {
      path,
      url: document.location.href,
    });
  }

  /**
   * Initialize the analytic services
   */
  load () {
    try {
      // Load segment
      if (process.env.SEGMENT_API_KEY) {
        if (typeof analytics === 'undefined' || analytics === null || !analytics.load) return;
        analytics.load(process.env.SEGMENT_API_KEY);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  /**
   * Send event to segment
   *
   * @param {String} eventName - The name of the event.
   * @param {Object} properties - A set of properties to include with the event you're sending
   */
  track (eventName, properties) {
    try {
      if (LocalStorageService.getItem('spoofing') === 'true') return; // Don't track admins the are spoofing

      if (typeof analytics === 'undefined' || analytics === null) return;
      analytics.track(eventName, properties);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  /**
   * Send event to segment
   *
   * @param {String} element - The DOM element to track clicks on.
   * @param {String} eventName - The name of the event.
   * @param {Object} properties - A set of properties to include with the event you're sending
   */
  trackLink (element, eventName, properties) {
    try {
      if (LocalStorageService.getItem('spoofing') === 'true') return; // Don't track admins the are spoofing

      if (typeof analytics === 'undefined' || analytics === null) return;
      analytics.trackLink(element, eventName, properties);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  /**
   * Return a unique UUID
   */
  getAnonymousUserId() {
    return uuidv1();
  }

  /**
   * Associate the current user to the tracking platforms
   *
   * @param {Object} user - The user to track.
   */
  identify (user) {
    try {
      if (LocalStorageService.getItem('spoofing') === 'true' || !user) return; // Don't track admins the are spoofing

      // Associate sentry user
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: user.id,
        });
      });

      // Associate segment user
      if (typeof analytics === 'undefined' || analytics === null) return;
      analytics.identify(user.id, {
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
        createdAt: user.created_at,
        gender: user.gender,
        renterRole: user.renter_role,
        role: user.role,
        appRole: user.role, // dup for Zendesk
        avatar: user.profile_image ? user.profile_image.url : null,
        age: user.age,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}

export default AnalyticsService;
